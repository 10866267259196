import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [~12,[2]],
		"/(app)/account/billing": [13,[2,3]],
		"/(app)/account/integrations": [14,[2,3]],
		"/(app)/account/notifications": [15,[2,3]],
		"/(app)/account/organization": [~16,[2,3]],
		"/(app)/account/profile": [17,[2,3]],
		"/(app)/account/update-email": [18,[2,3,4]],
		"/(app)/account/update-password": [19,[2,3,5]],
		"/(app)/admin": [~20,[2]],
		"/(app)/admin/users": [21,[2]],
		"/(app)/admin/users/[id]": [22,[2]],
		"/(app)/ai": [23,[2]],
		"/(auth)/auth/auth-code-error": [61,[9]],
		"/(app)/calendar": [24,[2]],
		"/(app)/courses": [25,[2,6]],
		"/(app)/courses/mux": [26,[2,6]],
		"/(app)/email": [~27,[2]],
		"/(app)/feed": [~28,[2]],
		"/(auth)/forgot-password": [62,[9]],
		"/(app)/gpt": [29,[2]],
		"/(app)/kanban": [30,[2]],
		"/(app)/linear": [~31,[2]],
		"/(app)/linear/issues/[id]": [~32,[2]],
		"/(app)/linear/projects/[id]": [~33,[2]],
		"/(app)/linear/teams": [~34,[2]],
		"/(app)/linear/teams/[id]": [~35,[2]],
		"/(auth)/login": [63,[9]],
		"/(app)/logout": [~36,[2]],
		"/(app)/map": [37,[2]],
		"/(app)/messages": [38,[2]],
		"/(app)/onboarding": [39,[2,7]],
		"/(app)/playground": [40,[2,8]],
		"/(app)/playground/charts": [41,[2,8]],
		"/(app)/playground/code": [42,[2,8]],
		"/(app)/playground/documents": [43,[2,8]],
		"/(app)/playground/form": [~44,[2,8]],
		"/(app)/playground/jobs/new": [46,[2,8]],
		"/(app)/playground/jobs/[id]/edit": [45,[2,8]],
		"/(app)/playground/super-form": [~47,[2,8]],
		"/(app)/playground/super-form/users": [~48,[2,8]],
		"/(app)/playground/test": [49,[2,8]],
		"/(app)/pricing": [~50,[2]],
		"/(app)/products": [51,[2]],
		"/(app)/products/id": [53,[2]],
		"/(app)/products/[id]": [52,[2]],
		"/(app)/projects": [~54,[2]],
		"/(app)/projects/[id]": [55,[2]],
		"/(app)/realtime": [56,[2]],
		"/(app)/realtime/presence": [57,[2]],
		"/(auth)/register": [64,[9]],
		"/sentry-example": [65],
		"/(app)/stripe/order": [58,[2]],
		"/(app)/stripe/payment": [59,[2]],
		"/(app)/stripe/success": [~60,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';